import { render, staticRenderFns } from "./feedback.vue?vue&type=template&id=084d4a42&scoped=true&"
import script from "./feedback.vue?vue&type=script&lang=js&"
export * from "./feedback.vue?vue&type=script&lang=js&"
import style0 from "./feedback.vue?vue&type=style&index=0&id=084d4a42&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084d4a42",
  null
  
)

export default component.exports